<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#68D389" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: #ffffff">
            {{ msg }}
          </span>
        </v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #ffffff">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>

    <v-layout wrap justify-center pb-4 px-3>
      <v-flex xs12 text-left>
        <span class="itemHeading"> Edit Product </span>
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center>
      <v-flex xs12 md12 px-md-3 px-lg-3 px-xl-3>
        <v-layout wrap>
          <v-flex xs12 md12>
            <v-layout wrap>
              <v-flex xs12 md12>
                <v-text-field
                  class="textField2"
                  dense
                  v-model="product.name"
                  outlined
                  label="Product Name"
                  required
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12 text-left>
                 <span class="textField1"
                  >Please Add the Product Description</span
                >
                <div id="app">
                  <vue-editor v-model="product.description"></vue-editor>
                </div>
              </v-flex>
              <v-flex xs12 md12 lg12 xl7>
                <v-layout wrap justify-center pt-3>
                  <v-flex xs12 text-left>
                    <span class="styloopinputlabel" style="font-size:16px">Product Units</span>
                  </v-flex>
                  <v-flex xs12 pt-3 v-if="product.units.length>0">
                    <StockAdder
                      v-bind:storage="product"
                      v-bind:sizeData="product.units"
                      @stepper="winStepper"
                    />
                  </v-flex>
                  <v-flex xs12 pt-3 v-else>
                    <StockAdder
                      v-bind:storage="product"
                      @stepper="winStepper"
                    />
                  </v-flex>
                  
              <v-flex xs12 md12 py-2>
                <v-text-field
                  v-model="product.price"
                  type="number"
                  outlined
                  placeholder="Product Price"
                  label="Product Price"
                  
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12 py-2>
                <v-text-field
                  v-model="product.totalQuantity"
                  type="number"
                  outlined
                  placeholder="Total Quantity"
                  label="Total Quantity"
                  
                  hide-details
                ></v-text-field>
              </v-flex>
              <!-- <v-flex xs12 md12 py-2>
                <v-text-field
                  v-model="product.stock"
                  type="number"
                  outlined
                  placeholder="Available Stock"
                  label="Available Stock"
                  
                  hide-details
                ></v-text-field>
              </v-flex> -->
              <v-flex xs12 md12 py-2>
                <v-text-field
                  v-model="product.weight"
                  type="number"
                  outlined
                  placeholder="Weight of product"
                  label="Weight of product"
                  
                  hide-details
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12 py-2 text-left>
                 <span class="styloopinputlabel">Unit Type </span>
                    <v-select
                      outlined
                      dense
                      placeholder="Unit Type"
                      v-model="product.unitType"
                      :items="unitTypes"
                      hide-details
                      item-text="name"
                      item-value="name"
                      class="text-left"
                    >
                    </v-select>
              </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
         <v-flex md12 xs12>
            <ImageComp
              @stepper="winStepper"
              :photos="details.allPhotos"
              :pageId="details._id"
              :height="'800'"
              :width="'600'"
              :type="'product'"
              :heading="'Upload Images'"
            />
          </v-flex>
          <v-flex xs12 pt-5>
            <CoverImageComp
              @stepper="winStepper"
              :singleImage="details.coverImage"
              :pageId="details._id"
              :height="'2118'"
              :width="'8001'"
              :heading="'Upload Cover Image'"
              :componentType="'coverImage'"
            />
          </v-flex>
          <v-flex xs12>
            <v-combobox
              v-model="product.speciality"
              :items="items"
              chips
              clearable
              label="Speciality"
              multiple
              solo
              class="pt-3"
            >
              <template v-slot:selection="{ attrs, item, select, selected }">
                <v-chip
                  v-bind="attrs"
                  :input-value="selected"
                  close
                  @click="select"
                  @click:close="remove(item)"
                >
                  <strong>{{ item }}</strong
                  >&nbsp;
                </v-chip>
              </template>
            </v-combobox>
          </v-flex>
          <v-flex md12 pt-4>
            <v-layout justify-end>
              <v-flex md3>
                <v-btn
                  block
                  tile
                  color="#68D389"
                  light
                  :ripple="false"
                  depressed
                  @click="validateInput"
                  class="itemValue"
                >
                  <span style="color: #ffffff">Save</span>
                </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import axios from "axios";
import StockAdder from "./stockAddrer";
import { VueEditor } from "vue2-editor";
import ImageComp from "@/components/CommonComponents/multipleImages";
import CoverImageComp from "@/components/CommonComponents/singleImage";
export default {
  components: {
    ImageComp,
    CoverImageComp,
      VueEditor,
     StockAdder,
  },
  props: ["id"],
  data() {
    return {
      product: {
        name: null,
        description: null,
        units: [],
        speciality:null,
        totalQuantity:null,
        price:null,
        weight: null,
        unitType: null,
      },
      unitTypes:[
          {
            name:"grams"
          },
          {
            name:"count"
          },
        ],
      valid: false,
      imageArray: [],
      details:[],
      imageError: "",
      imageCoverError: "",
      formData: new FormData(),
      selectedFiles: [],
      image: null,
      coverImageArray: [],
      formDataCover: new FormData(),
      date: new Date().toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      appLoading: false,
      ServerError: false,
      showSnackBar: false,
      timeout: 5000,
      msg: null,
      slotAddition: false,
      packageRate: [],
      selectedImage: null,
      categories: [],
      coverImageFile: null,
      productStock: {},
      imageFiles: [],
      productId:null,
      items: [],
    };
  },
  beforeMount() {
    this.getData();
  },
  methods: {
    winStepper(window_data) {
       if (window_data.type == "image") {
        this.imageArray = window_data.imageArray;
        this.imageFiles.push(window_data.selectedFiles);
      } else if (window_data.type == "imageRemoval") {
        var values = this.formData.getAll("photos");
        values.splice(window_data.removalItem, 1);
        this.formData.set("photos", values);
        this.imageArray = window_data.imageArray;
      } else if (window_data.type == "coverImage") {
        this.coverImageFile = window_data.selectedFiles;
      }
      else if (window_data.productStock) {
        this.productStock = window_data.productStock;
        this.product.units = this.productStock;
        // if(this.winStepper.type=="edit")
        // this.getData();
      }
      
      else if (window_data.unitUpdate) {
        this.productStock = window_data.unitUpdate;
        this.product.units=this.productStock
      }
      else if (window_data.type == "imageDeletion")
      {
      this.getData();
    }
    },
    validateInput() {
      if (!this.product.name) {
        this.msg = "Please Provide Product name";
        this.showSnackBar = true;
        return;
      } else if (!this.product.description) {
        this.msg = "Please Provide Description";
        this.showSnackBar = true;
        return;
      } else if (!this.imageFiles.length >1 && this.details.allPhotos.length>0) {
        this.msg = "Please Upload Image";
        this.showSnackBar = true;
        return;
      } else if (!this.coverImageFile && !this.details.coverImage) {
        this.msg = "Please Upload Cover Image";
        this.showSnackBar = true;
        return;
      }
      else if (!this.product.unitType) {
        this.msg = "Please provide Unit Type";
        this.showSnackBar = true;
        return;
      } else if (this.product.units.length <= 0) {
        if (!this.product.totalQuantity) {
          this.msg = "Please provide Total Quantity";
          this.showSnackBar = true;
          return;
        }
        if (!this.product.price) {
          this.msg = "Please provide Price";
          this.showSnackBar = true;
          return;
        }
        if (!this.product.weight) {
          this.msg = "Please provide Weight";
          this.showSnackBar = true;
          return;
        } else {
          this.editProduct();
        }
      }
      
      
      else {
        this.editProduct();
      }
    },
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/product/get",
        params: {
          id: this.id,
          //   date: new Date(),
        },
      })
        .then((response) => {
          this.appLoading = false;
          this.details = response.data.data;
          this.product = this.details;
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    remove(item) {
      this.product.speciality.splice(
        this.product.speciality.indexOf(item),
        1
      );
      this.product.speciality = [...this.product.speciality];
    },
    editProduct() {
         this.product.id = this.id;
      axios({
        method: "post",
        url: "/product/edit",
        data: this.product,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Product Edited Successully";
            // Object.keys(this.product).forEach(
            //   (key) => (this.product[key] = null)
            // );
            if (this.imageArray.length > 0) {
              for (var i = 0; i < this.imageFiles.length; i++)
                this.formData.append("images", this.imageFiles[i]);
              this.uploadMainImages(this.id);
            }
            if (this.coverImageFile) {
              this.uploadCoverImages(this.id);
            }

            this.showSnackBar = true;
        this.getData();

          } else {
            this.msg = response.data.msg;
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadMainImages(item) {
      this.appLoading = true;
      this.formData.append("id", item);
      axios({
        method: "POST",
        url: "/product/images",
        data: this.formData,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg="Uploaded Successully"
            this.imageArray=[]
                      window.location.reload()

          } else {
            this.msg = "Can't Upload Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.msg = "Can't Upload Image.. Please Try Again Later";
          this.showSnackBar = true;
          this.appLoading = false;
          console.log(err);
        });
    },
    uploadCoverImages(item) {
      this.appLoading = true;
      this.formDataCover.append("id", item);
      this.formDataCover.append("image", this.coverImageFile);
      axios({
        method: "POST",
        url: "/product/UploadCoverImage",
        data: this.formDataCover,
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
           this.msg="Uploaded Successully"
           this.coverImageFile=null
          
          } else {
            this.msg = "Can't Upload Cover Image.. Please Try Again Later";
            this.showSnackBar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.msg = "Can't Upload Cover Image.. Please Try Again Later";
          this.showSnackBar = true;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.icon {
  padding-left: 170px;
}
.text {
  text-align: left;
  font-family: poppinsRegular;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9e9e9e;
}
.v-application .primary {
  background-color: red !important;
}
</style>